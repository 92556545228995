import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbortBoarding, OffboardBatch, OnboardBatch, Stage, StationsStage } from '@models/onboarding/onboarding.class';
import { PaginatedList } from '@app/models/common/paginated-list.class';

@Injectable()
export class OnboardingService {
    private uri: string = `${environment.routes.apiEndpoint}/onboarding`;
    constructor(private http: HttpClient) { }

    public getStages() {
      return this.http.get<PaginatedList<Stage>>(`${this.uri}/stages`);
    }

    public onboardedStagePerStation(stationId: string) {
      return this.http.get<StationsStage[]>(`${this.uri}/stations/stages/onboarded`, {params: {stationIds: encodeURI(stationId)}});
    }

    public onboardStages(onboardings: OnboardBatch[]) {
      return this.http.put(`${this.uri}/stages/onboard`, {stages: onboardings});
    }

    public offboardStages(offboardings: OffboardBatch[]) {
      return this.http.put(`${this.uri}/stages/offboard`, {stages: offboardings});
    }

    public abortBoarding(abortBoardings: AbortBoarding[]) {
      return this.http.delete(`${this.uri}/stages/boarding`, { body: {stages: abortBoardings} });
    }
}
